import React from 'react'
import S from '../Report.style'
import { CATEGORY_COGNITION_NAME } from 'src/common/constant'

const CategoryCognitionTable = () => {
  return (
    <div css={S.CategoryCognitionTable}>
      <div className="title">문항인지요소</div>
      <p className="description">각 문항에서 평가하는 학생들의 수학적 역량입니다.</p>
      <article>
        <div className="header">
          <div className="col1">
            <p>문항인지 요소</p>
          </div>
          <div className="col2">
            <p>설명</p>
          </div>
        </div>
        {CATEGORY_COGNITION_NAME.map(({ name, description }) => (
          <div className="row">
            <div className="col1">
              <p>{name}</p>
            </div>
            <div className="col2">
              <p>{description}</p>
            </div>
          </div>
        ))}
      </article>
      <p className="sub-d">[참고] 학년에 따라 사용된 문항인지요소가 다를 수 있습니다.</p>
    </div>
  )
}

export default CategoryCognitionTable
